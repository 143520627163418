<template>
	<div class="container flip-clock timer">
		<template
			v-for="data in timeData"
			v-show="show"
		>
			<div
				:id="data.elementId"
				:key="data.label"
				class="flip-clock__piece"
			>
				<div class="flip-clock__card flip-card">
					<div class="flip-card__top digit">
						{{ data.current | twoDigits }}
					</div>
				</div>
				<div class="flip-clock__slot label">
					{{ data.label }}
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

const interval = null;

export default {
	name: 'FlipCountdown',
	filters: {
		twoDigits(value) {
			if (value.toString().length <= 1) {
				return `0${value.toString()}`;
			}
			return value.toString();
		},
	},
	props: {
		deadline: {
			type: String,
		},
		server_time: {
			type: String,
		},
		stop: {
			type: Boolean,
		},
		labels: {
			type: Object,
			required: false,
			default() {
				return {
					days: 'дней',
					hours: 'часов',
					minutes: 'минут',
					seconds: 'секунд',
				};
			},
		},
	},
	data() {
		const uuid = uuidv4();
		return {
			now: Math.trunc(new Date().getTime() / 1000),
			date: null,
			interval: null,
			diff: 0,
			show: false,
			timeData: [
				{
					current: 0,
					previous: 0,
					label: this.labels.days,
					elementId: `flip-card-days-${uuid}`,
				},
				{
					current: 0,
					previous: 0,
					label: this.labels.hours,
					elementId: `flip-card-hours-${uuid}`,
				},
				{
					current: 0,
					previous: 0,
					label: this.labels.minutes,
					elementId: `flip-card-minutes-${uuid}`,
				},
				{
					current: 0,
					previous: 0,
					label: this.labels.seconds,
					elementId: `flip-card-seconds-${uuid}`,
				},
			],
		};
	},
	computed: {
		seconds() {
			return Math.trunc(this.diff) % 60;
		},
		minutes() {
			return Math.trunc(this.diff / 60) % 60;
		},
		hours() {
			return Math.trunc(this.diff / 60 / 60) % 24;
		},
		days() {
			return Math.trunc(this.diff / 60 / 60 / 24);
		},
	},
	watch: {
		deadline(newVal, oldVal) {
			const endTime = this.deadline;
			this.date = Math.trunc(Date.parse(endTime.replace(/-/g, '/')) / 1000);
			if (!this.date) {
				throw new Error("Invalid props value, correct the 'deadline'");
			}
		},
		now(value) {
			this.diff = this.date - this.now;
			if (this.diff <= 300 || this.stop) {
				const youtube = document.querySelector('#youtube-block');
				if (youtube) {
					youtube.classList.remove('d-none');
				}
				const counterBlock = document.querySelector('#counter-block');
				// youtube.style.display = "inline-block";
				counterBlock.style.display = 'none';

				this.diff = 0;
				this.updateTime(3, 0);
				// setTimeout(this.goToYoutube, 1000*60);
			} else {
				this.updateTime(0, this.days);
				this.updateTime(1, this.hours);
				this.updateTime(2, this.minutes);
				this.updateTime(3, this.seconds);
			}
		},
	},
	created() {
		if (!this.deadline) {
			throw new Error("Missing props 'deadline'");
		}
		const endTime = this.deadline;
		this.date = Math.trunc(Date.parse(endTime.replace(/-/g, '/')) / 1000);
		this.now = Math.trunc(Date.parse(this.server_time.replace(/-/g, '/')) / 1000);

		if (!this.date) {
			throw new Error("Invalid props value, correct the 'deadline'");
		}
		this.interval = setInterval(() => {
			this.now += 1;
		}, 1000);
	},
	mounted() {
		if (this.diff !== 0) {
			this.show = true;
		}
	},
	beforeDestroy() {
		if (window.cancelAnimationFrame) {
			cancelAnimationFrame(this.frame);
		}
	},
	destroyed() {
		clearInterval(interval);
	},
	methods: {
		goToYoutube() {
			window.location.href = 'https://youtu.be/UjUO4qh7VQE';
		},
		updateTime(idx, newValue) {
			if (idx >= this.timeData.length || newValue === undefined) {
				return;
			}
			if (window.requestAnimationFrame) {
				this.frame = requestAnimationFrame(this.updateTime.bind(this));
			}
			const d = this.timeData[idx];
			const val = newValue < 0 ? 0 : newValue;
			const el = document.querySelector(`#${d.elementId}`);
			if (val !== d.current) {
				d.previous = d.current;
				d.current = val;
				if (el) {
					el.classList.remove('flip');
					void el.offsetWidth;
					el.classList.add('flip');
				}
				if (idx === 0) {
					const els = el.querySelectorAll('span b');
					if (els) {
						for (const e of els) {
							const cls = e.classList[0];
							if (newValue / 1000 >= 1) {
								if (!cls.includes('-4digits')) {
									const newCls = `${cls}-4digits`;
									e.classList.add(newCls);
									e.classList.remove(cls);
								}
							} else if (cls.includes('-4digits')) {
								const newCls = cls.replace('-4digits', '');
								e.classList.add(newCls);
								e.classList.remove(cls);
							}
						}
					}
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.flip-clock__piece {
	margin-right: 1rem
}
</style>
