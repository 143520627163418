<template>
	<div
		id="html-with-background"
		:class="'panel themes ' + content.theme"
		:style="'background-image:url(' + content.background_image + ')'"
	>
		<div class="container">
			<div class="panel_header">
				<div class="row align-items-end">
					<div class="col-12">
						<h2>{{ $t('topics') }} {{ fetchedData.type_titles.title_genitive }}</h2>
					</div>
				</div>
				<!-- <nuxt-link class="panel_control" to="#"></nuxt-link> -->
			</div>
			<div class="themes_block">
				<div v-html="content.html.ru" />
			</div>
			<div class="panel_footer">
				<div class="row">
					<div class="col-12">
						<nuxt-link to="#empty-registration" class="btn btn-primary">
							{{ $t('register') }}
						</nuxt-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HtmlWithBackground',
	props: [
		'content',
		'static_content',
		'block_title',
		'fetchedData',
	],
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-themes";
</style>

<i18n lang="yaml">
ru:
  topics: "Темы"
en:
  topics: "Topics"
</i18n>
