<template>
	<form @submit.prevent="onSubmit">
		<b-row>
			<b-col cols="12" md="12" lg="12">
				<b-input-group class="input-group-pass">
					<b-form-input
						id="password"
						v-model.trim="form.fields.password"
						name="password"
						type="text"
						class="form-control rounded-0 required"
						aria-describedby="input-password-feedback"
						autocomplete="off"
						required
						autofocus
						:state="valid('password')"
					/>
					<b-input-group-append>
						<b-button
							type="submit"
							variant="primary"
							:disabled="form.sending"
						>
							<b-spinner
								v-if="form.sending"
								style="width: 1.3em; height: 1.3em; margin-right: 15px;"
							/>
							ОK
						</b-button>
					</b-input-group-append>
					<b-form-invalid-feedback id="input-password-feedback">
						<div v-for="msg in form.errors.password" :key="msg">
							{{ msg }}
						</div>
					</b-form-invalid-feedback>
				</b-input-group>
			</b-col>
		</b-row>
	</form>
</template>

<script>
import formMixin from '@/mixins/formMixin';

export default {
	mixins: [formMixin],
	methods: {
		async onSubmit() {
			if (this.form.sending) return;

			this.form.sending = true;

			const passwords = ['qwerty', 'tyuiop', 'ytrewq', 'poiuytr', 'wertyu', 'media', 'equis'];
			if (passwords.includes(this.form.fields.password)) {
				this.$emit('success');

				const { UAClientID } = window;

				window.dataLayer = window.dataLayer || [];
				dataLayer.push({
					event: 'mps_password',
					form_data: this.form.fields.password,
				});

				this.$api.axios.get('https://sk.skolkovo.ru/qr/', {
					params: {
						a: this.form.fields.password,
						b: typeof UAClientID !== 'undefined' ? UAClientID : '',
					},

				});

				this.form.error = null;
				this.form.errors = {};
				this.form.sent = true;
			} else {
				this.form.sent = false;
				this.form.message = null;
				this.form.errors = { password: ['Неправильный пароль / Wrong Password'] };
			}

			this.form.fields = {};
			this.form.sending = false;
		},
	},
};
</script>

<style lang="scss" scoped>
	@import 'assets/styles/sk_variables';
	// For use bootstrap vars and mixins inside
	@import "assets/styles/bootstrap/scss/functions";
	@import "assets/styles/bootstrap/scss/variables";
	@import "assets/styles/bootstrap/scss/mixins";

	.input-group-pass {
		@include media-breakpoint-down(sm) {
			flex-flow: column;
			input {
				margin-bottom: 1rem;
			}

		}

	}
	.invalid-feedback {
		position: absolute;
		bottom: -2rem;
	}
	.was-validated .form-control:invalid, .form-control.is-invalid {
		padding-right: calc(0em + 0.75rem) !important;;
	}
</style>
