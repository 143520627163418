const jsonldForProgrammes = (data) => {
	const jsonldData = {
		'@context': 'http://schema.org',
		'@type': 'Course',
		name: data.title,
		description: data.subtitle,
		provider: {
			'@type': 'Organization',
			name: 'Moscow School of Management SKOLKOVO',
			sameAs: 'https://www.skolkovo.ru/',
		},
	};

	return jsonldData;
};

export default jsonldForProgrammes;
