import { render, staticRenderFns } from "./ProgrammHeader.vue?vue&type=template&id=20f96e4f&scoped=true"
import script from "./ProgrammHeader.vue?vue&type=script&lang=js"
export * from "./ProgrammHeader.vue?vue&type=script&lang=js"
import style0 from "./ProgrammHeader.vue?vue&type=style&index=0&id=20f96e4f&prod&lang=scss&scoped=true"
import style1 from "./ProgrammHeader.vue?vue&type=style&index=1&id=20f96e4f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f96e4f",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BDropdownItemButton, BDropdown} from 'bootstrap-vue'
    installComponents(component, {BDropdownItemButton, BDropdown})
    


    import installDirectives from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-tooltip': VBTooltip})
    

export default component.exports