<template>
	<div id="panel_teaser" class="panel teaser">
		<div class="container">
			<div class="panel_header">
				<div class="row">
					<div class="col">
						<h2 v-if="block.block_title === null">
							Презентация программы
						</h2>
						<h2 v-else>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
			</div>
			<ul class="announce_list">
				<li v-for="(item, index) in block.content" :key="index" class="row">
					<div class="col-12 col-lg-4">
						<div class="announce_image_block video">
							<img :src="item.listing_cover_default.src">
						</div>
					</div>
					<div class="col-12 col-lg-8">
						<div class="announce_content_block">
							<div class="announce_content_middle">
								<p class="title">
									{{ item.title }}
								</p>
								<p class="description">
									{{ item.intro }}
								</p>
							</div>
							<div class="announce_content_footer">
								<button class="btn btn-link more_link video" @click="showModal(item.video_code);">
									Смотреть
								</button>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<div class="panel_footer">
				<a v-scroll-to="'#empty-registration'" href="#" class="btn btn-primary">Перейти к регистрации</a>
			</div>
		</div>
		<b-modal id="bv-modal-video" hide-footer>
			<div class="d-block text-center">
				<b-embed
					v-if="modalVideoSrc"
					:src="modalVideoSrc + '?rel=0&enablejsapi=1'"
					type="iframe"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameborder="0"
					allowfullscreen
				/>
			</div>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'ProgTeaser',
	props: {
		block: { type: Object, required: true },
		fetchedData: { type: Object, required: true },
	},
	data() {
		return {
			modalVideoSrc: null,
		};
	},
	methods: {
		showModal(src) {
			this.$bvModal.show('bv-modal-video');
			this.modalVideoSrc = src;
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-announces";
</style>
