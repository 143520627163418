export default {
	mounted() {
		try {
			if (this.$options && typeof this.$options.analyticTags === 'function') {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: 'ajax_page_load',
					tags: this.$options.analyticTags.call(this),
				});
			}
		} catch (e) {
			console.log('googleAnalytics error: ', e);
		}
	},
};
