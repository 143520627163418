<template>
	<div
		v-if="event"
		:class="eventBranding"
	>
		<div v-if="event.id === 9636 && switcher === false" class="container pass">
			<div class="row d-flex justify-content-center">
				<div class="col-12 col-md-6 col-lg-4">
					<div class="title mb-3">
						Пароль / Password
					</div>
					<div>
						<password-form @success="onSuccess" />
					</div>
				</div>
			</div>
		</div>

		<div v-else>
			<programm-header
				v-if="event.typology === TYPOLOGY.PROGRAM"
				ref="program-header"
				:fetched-data="event"
				:links="links"
			/>
			<event-header v-else :fetched-data="event" />

			<alumni-header v-if="check" :event="event" />

			<div v-if="showForRoles || !notAlumniPage">
				<div v-for="(block, index) in filteredBlocks" :key="index">
					<component
						:is="block.type"
						v-if="!block.on_broadcast_page"
						:ref="block.type"
						:index="index"
						:block="block"
						:content="block.content"
						:fetched-data="event"
						:static_content="block.static_content"
						:block_title="block.block_title"
					/>
				</div>
			</div>

			<input id="landingId" type="hidden" :value="event.landing_id">
			<input id="eventId" type="hidden" :value="event.id">
			<input
				v-if="event && event.issue !==null"
				id="okUrl"
				type="hidden"
				:value="'/' + event.typology_slug + '/' + event.slug + '/ok-success/'"
			>

			<main-footer-eng v-if="$i18n.locale ==='en'" :fetched-data="event" />
			<main-footer v-else :fetched-data="event" />
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import panels from '@/mixins/panels';
import googleAnalytics from '@/mixins/googleAnalytics';

import jsonldForProgrammes from '@/utils/seo/jsonldForProgrammes';
import { TYPOLOGY } from '@/utils/dictionaries';
import jsonldForEvents from '@/utils/seo/jsonldForEvents';

import RegistrationCounterImage from '@/components/refactor/RegistrationCounterImage.vue';

import GraduateReviews from '@/components/refactor/GraduateReviews.vue';
import Presentations from '@/components/refactor/Presentations.vue';

import EventHeader from '@/components/layouts/headers/EventHeader.vue';
import ProgrammHeader from '@/components/layouts/headers/ProgrammHeader.vue';
import MainFooter from '@/components/layouts/footers/MainFooter.vue';

import HtmlWithBackground from '@/components/refactor/HtmlWithBackground.vue';
import HtmlRaw from '@/components/refactor/HtmlRaw.vue';
import MainFooterEng from '~/components/layouts/footers/MainFooterEng.vue';
import PasswordForm from '@/components/PasswordForm.vue';
import AlumniHeader from '~/components/alumniHeader.vue';
import makeCanonicalLink from '~/utils/seo/makeCanonicalLink';

import moment from '@/utils/moment';

export default {
	name: 'EventPage',
	components: {
		AlumniHeader,
		HtmlWithBackground, // удалить после переноса
		HtmlRaw, // удалить после переноса
		EventHeader,
		MainFooter,
		MainFooterEng,
		ProgrammHeader,
		GraduateReviews,
		Presentations,
		RegistrationCounterImage,
		PasswordForm,
	},
	mixins: [
		panels,
		googleAnalytics,
	],
	// eslint-disable-next-line complexity
	async asyncData({
		app, params, route, redirect,
	}) {
		const { $pagesApi, i18n } = app;
		const { slug } = params;

		const event = await $pagesApi.event(slug);

		if (!event) return {};

		if (route.name === 'programmes-detail' && event.typology === 0) {
			return redirect(`/events/${event.slug}/`);
		} if (route.name === 'events-detail' && event.typology === 1) {
			return redirect(`/programmes/${event.slug}/`);
		}

		return { event };
	},
	data() {
		return {
			event: null,
			TYPOLOGY,
			switcher: false,
		};
	},
	async fetch() {
		await this.$store.dispatch('profile/getRoles');
	},
	head() {
		return {
			title: `${this.event.type_titles.title_default}: ${this.event.meta_title} - ${this.makeDate()} - Школа управления СКОЛКОВО`,
			link: [
				{
					rel: 'canonical',
					hid: 'canonical',
					href: makeCanonicalLink(this.$route.path),
				},
			],
			meta: [
				{
					hid: 'robots',
					name: 'robots',
					content: this.event.meta_indexing,
				},
				{
					name: 'description',
					hid: 'description',
					content: `${this.event.meta_description} в бизнес-школе СКОЛКОВО`,
				},
				// Open Graph
				{
					name: 'og:title',
					hid: 'og:title',
					content: `${this.event.type_titles.title_default}: ${this.event.meta_title} - ${this.makeDate()} - Школа управления СКОЛКОВО`,
				},
				{
					name: 'og:description',
					hid: 'og:description',
					content: `${this.event.meta_description} в бизнес-школе СКОЛКОВО`,
				},
				{
					name: 'og:type',
					hid: 'og:type',
					content: 'article',
				},
				{
					name: 'og:image',
					hid: 'og:image',
					content:
						this.event.smm_image_default.src
						|| this.event.top_cover_image_url_default,
				},
				// Twitter Card
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:site',
					content: '@nuxt_js',
				},
				{
					name: 'twitter:title',
					content: this.event.meta_title,
				},
				{
					name: 'twitter:description',
					content: this.event.meta_description,
				},
				{
					name: 'twitter:image',
					content: this.event.smm_image_default.src
						|| this.event.top_cover_image_url_default,
				},

				{
					name: 'image',
					property: 'og:image',
					content: this.event.smm_image_default.src
						|| this.event.top_cover_image_url_default,
				},
				{
					name: 'author',
					content: 'SKOLKOVO',
				},
			],
		};
	},
	computed: {
		eventBranding() {
			return this.event.branding;
		},
		links() {
			const { event_pages: pages, slug, title } = this.event;
			if (!Array.isArray(pages) || pages.length === 0) return false;
			const links = pages.map(({ slug: path, title: name, active }) => ({
				path: `/programmes/${slug}/${path}/`,
				name,
				active,
			}));
			links.unshift({ path: `/programmes/${slug}/`, title, home: true });

			return links;
		},
		isEvent() {
			return this.event.typology === TYPOLOGY.EVENT;
		},
		check() {
			return (!this.$auth.user && this.$store.state.alumni.eventDetail)
				|| (this.event.is_alumni && !this.isAlumni);
		},
		isAlumni() {
			return this.$store.getters['profile/isAlumni'];
		},
		showForRoles() {
			const { roles } = this.$store.state.profile;

			if (roles) {
				return roles.includes('alumni') || roles.includes('manager') || roles.includes('Admin');
			}

			return false;
		},
		notAlumniPage() {
			return this.$store.state.alumni.eventDetail;
		},
		filteredBlocks() {
			return this.event.blocks.filter((block) => block.is_alumni_block !== 4);
		},
	},
	mounted() {
		this.goToAnchor();
		if (this.switcher === false) {
			this.switcher = JSON.parse(sessionStorage.getItem('logged'));
			if (this.switcher !== true) {
				this.switcher = false;
			}
		}
	},
	created() {
		if (process.client) {
			if (this.event) {
				if (this.event.taxonomy.project && this.event.taxonomy.project.chat_id) {
					this.loadJivo(this.event.taxonomy.project.chat_id);
				} else {
					this.loadJivo();
				}
			}
		}
		this.checkValue();
		if (this.$store.state.alumni.eventDetail && !this.$auth.loggedIn) {
			this.showLoginPopup();
		}
	},
	analyticTags() {
		return {
			project_type: this.event.type_titles.title_default,
			project_product: this.event.taxonomy.product ? this.event.taxonomy.product.title : '',
			project_direction: this.event.taxonomy.project ? this.event.taxonomy.project.title : '',
			project_profile_section: this.event.taxonomy.section ? this.event.taxonomy.section.title : '',
			// subject: this.event.topics, // todo проработать массив
			project_demand: this.event.taxonomy.taxonomy_target ? this.event.taxonomy.taxonomy_target : '',
			project_objective: this.event.taxonomy.client_needs ? this.event.taxonomy.client_needs : '',
			project_marketing_task: this.event.taxonomy.project_marketing_task ? this.event.taxonomy.project_marketing_task : '',
			project_terms: this.event.taxonomy.project_terms ? this.event.taxonomy.project_terms : '',
			project_format: this.event.taxonomy.project_format ? this.event.taxonomy.project_format : '',
			project_location: this.event.taxonomy.project_location ? this.event.taxonomy.project_location : '',
			project_audience: this.event.taxonomy.project_audience ? this.event.taxonomy.project_audience : '',
			project_profile_section_second: this.event.taxonomy.project_profile_section_second ? this.event.taxonomy.project_profile_section_second : '',
			project_cfo_guid: this.event.taxonomy.project_cfo_guid ? this.event.taxonomy.project_cfo_guid : '',
			project_guid: this.event.taxonomy.project_guid ? this.event.taxonomy.project_guid : '',
			project_sale_type: this.event.taxonomy.project_sale_type ? this.event.taxonomy.project_sale_type : '',
		};
	},
	jsonld() {
		let data = {};
		try {
			data = this.isEvent ? jsonldForEvents(this.event) : jsonldForProgrammes(this.event);
		} catch (e) {
			this.$logger.log(e);
		}

		return data;
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
			loadJivo: 'scripts/loadJivo',
		}),
		...mapMutations({
			checkAlumni: 'alumni/eventDetail',
		}),
		makeDate() {
			return `${moment(this.event.event_date).format('DD MMMM YYYY')} года`;
		},
		onSuccess() {
			this.$i18n.locale = 'en';
			this.switcher = true;
			sessionStorage.setItem('logged', JSON.stringify(this.switcher));
		},
		checkValue() {
			if (this.event.is_alumni === true) {
				this.checkAlumni(true);
			}
		},
		goToAnchor() {
			if (this.$route.hash) {
				const anchor = this.$route.hash.split('?')[0];
				if (window.location.hash.split('?')[0] === anchor) {
					const el = document.getElementById(anchor.slice(1));
					if (el) {
						window.scrollTo(0, el.offsetTop);
					}
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~/assets/styles/sk_variables';
@import "~/assets/styles/bootstrap/scss/functions";
@import "~/assets/styles/bootstrap/scss/variables";
@import "~/assets/styles/bootstrap/scss/mixins";

.alumni {
	@import "~/assets/styles/style_alumni";
	@import "~/assets/styles/bootstrap/scss/bootstrap";
}
.moove {
	@import "~/assets/styles/style_moove";
	@import "~/assets/styles/bootstrap/scss/bootstrap";
}
.hsemba {
	@import "~/assets/styles/style_hsemba";
	@import "~/assets/styles/bootstrap/scss/bootstrap";
}

.pass {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%)
}
</style>
