<template>
	<form class="" data-role="validator" name="landingForm" @submit.prevent="submit">
		<div v-if="format == 1">
			<div
				class="form-row"
			>
				<div
					class="col"
					:class="{ 'form-group--error': $v.firstName.$error }"
				>
					<input
						v-model.trim="$v.firstName.$model"
						:data-msg="$t('fill-name-message')"
						name="first_name"
						:placeholder="$t('name-placeholder') + '*'"
						type="text"
						class="form-control required"
					>
				</div>
			</div>
			<div
				class="form-row"
			>
				<div
					class="col"
					:class="{ 'form-group--error': $v.lastName.$error }"
				>
					<input
						v-model.trim="lastName"
						:data-msg="$t('fill-last-name-message')"
						name="last_name"
						:placeholder="$t('last-name-placeholder') + '*'"
						type="text"
						class="form-control required"
					>
				</div>
			</div>

			<div class="form-row">
				<div
					class="col"
					:class="{ 'form-group--error': $v.companyName.$error }"
				>
					<input
						v-model.trim="companyName"
						:data-msg="$t('fill-company-name-message')"
						name="company_name"
						:placeholder="$t('company-placeholder') + '*'"
						type="text"
						class="form-control required"
					>
				</div>
			</div>
			<div class="form-row">
				<div
					class="col"
					:class="{ 'form-group--error': $v.email.$error }"
				>
					<input
						v-model="email"
						data-msg="Пожалуйста, укажите ваш email"

						name="email"
						type="email"
						placeholder="Email*"
						class="form-control required"
					>
				</div>
			</div>
			<div class="form-row">
				<div
					class="col"
					:class="{ 'form-group--error': showPhoneValidationError }"
				>
					<input-phone
						v-model="mobilePhone"
						color="#ccc"
						:dark="true"
						:no-country-selector="false"
						@update="phoneUpdate"
					/>
				</div>
			</div>
			<div>
				<button
					:disabled="disableSubmitButton"
					class="btn btn-primary my-3"
					type="submit"
					name="action"
				>
					<b-spinner
						v-if="disableSubmitButton"
						style="margin-right: 10px; width: 1.3em; height: 1.3em;"
					/>
					{{ $t('register') }}
				</button>
				<div v-if="showPersonalData" class="custom-control custom-checkbox mb-3">
					<input
						id="personal-data-agreement-bf"
						v-model="agreements.personal_data"
						type="checkbox"
						name="personalDataAgreement"
						class="custom-control-input"
						data-action="checkout-personal-data-agreement"
					>
					<label
						class="custom-control-label"
						for="personal-data-agreement"
					>
						{{ $t('agreeWith') }} <a target="_blank" href="https://www.skolkovo.ru/static/personaldata_rus.html" />{{ $t('personalData') }}
					</label>
					<label
						v-if="!$v.agreements.personal_data.checked"
						class="error"
						for="personal-data-agreement-bf"
					>Пожалуйста, подтвердите согласие на обработку персональных данных</label>
				</div>
				<div v-if="showMarketingEmails" class="custom-control custom-checkbox mb-3">
					<input
						id="marketing-emails-agreement-bf"
						v-model="agreements.marketing_emails"
						type="checkbox"
						name="marketingEmailsAgreement"
						class="custom-control-input"
						data-action="checkout-marketing-emails-agreement"
					>
					<label
						class="custom-control-label"
						for="marketing-emails-agreement-bf"
					>{{ $t('isAgreesToMarketingEmails') }}</label>
				</div>
			</div>
		</div>

		<div v-else-if="format === 2" class="">
			<div
				class="form-row"
			>
				<div
					class="col-12 col-sm-6"
					:class="{ 'form-group--error': $v.firstName.$error }"
				>
					<input
						v-model.trim="$v.firstName.$model"
						:data-msg="$t('fill-name-message')"
						name="first_name"
						:placeholder="$t('name-placeholder') + '*'"
						type="text"
						class="form-control required"
					>
				</div>
				<div
					class="col-12 col-sm-6"
					:class="{ 'form-group--error': $v.lastName.$error }"
				>
					<input
						v-model.trim="lastName"
						:data-msg="$t('fill-last-name-message')"
						name="last_name"
						:placeholder="$t('last-name-placeholder') + '*'"
						type="text"
						class="form-control required"
					>
				</div>
			</div>
			<div v-if="scenario !='REQUEST_SUBSCRIPTION' " class="form-row">
				<div
					v-if="fetchedData.id === 2672"
					class="col-12 col-sm-6"
					:class="{ 'form-group--error': $v.companyName.$error }"
				>
					<input
						v-model.trim="companyName"
						data-msg="Пожалуйста, укажите ваш университет"

						name="company_name"
						placeholder="Университет*"
						type="text"
						class="form-control required"
					>
				</div>

				<div
					v-else
					class="col-12 relative"
					:class="{ 'form-group--error': $v.companyName.$error }"
				>
					<DadataCompany
						v-model="companyName"
						name="company_name"
						class="company-input"
						:input-options="{placeholder: $t('company-placeholder') + '*'}"
						:style-classes="'form-control required dadata-form'"
						@setCompanyInfo="(value) => fullCompanyInfo = value"
					/>
				</div>

				<div
					class="col-12"
					:class="{ 'form-group--error': $v.industry.$error }"
				>
					<b-form-select
						v-model.trim="industry"
						:data-msg="$t('fill-industry-message')"
						name="industry"
						class="form-control required select-form"
						:class="industry ? '' : 'select-form__disabled'"
					>
						<b-form-select-option :value="null" disabled>
							{{ $t('industry-placeholder') + '*' }}
						</b-form-select-option>
						<b-form-select-option
							v-for="option in industries"
							:key="option.id"
							:value="option.id"
						>
							{{ $i18n.locale === 'ru' ? option.name_ru : option.name_en }}
						</b-form-select-option>
					</b-form-select>
				</div>

				<div
					v-if="fetchedData.id === 2672"
					class="col-12 col-sm-6"
					:class="{ 'form-group--error': $v.jobTitle.$error }"
				>
					<input
						v-model.trim="jobTitle"
						data-msg="Пожалуйста, укажите ваш факультет"

						name="job_title"
						placeholder="Факультет*"
						type="text"
						class="form-control required"
					>
				</div>

				<div
					v-else
					class="col-12"
					:class="{ 'form-group--error': $v.jobTitle.$error }"
				>
					<input
						v-model.trim="jobTitle"
						:data-msg="$t('fill-job-message')"
						name="job_title"
						:placeholder="$t('job-placeholder') + '*'"
						type="text"
						class="form-control required"
					>
				</div>
			</div>

			<div class="form-row">
				<div
					class="col"
					:class="{ 'form-group--error': $v.email.$error }"
				>
					<input
						v-model="email"
						data-msg="Пожалуйста, укажите ваш email"

						name="email"
						type="email"
						placeholder="Email*"
						class="form-control required"
					>
				</div>
			</div>
			<div class="form-row">
				<div
					class="col"
					:class="{ 'form-group--error': showPhoneValidationError }"
				>
					<input-phone
						v-model="mobilePhone"
						color="#ccc"
						:dark="true"
						:no-country-selector="false"
						@update="phoneUpdate"
					/>
				</div>
			</div>
			<div class="form-row">
				<div
					class="col"
					:class="{ 'form-group--error': $v.city.$error }"
				>
					<DaDataCity
						v-model="fullCityInfo"
						name="city"
						:initial-value="city"
						:input-options="{placeholder: $t('city-placeholder') + '*'}"
						:style-classes="'form-control required dadata-form'"
					/>
				</div>
			</div>
			<div class="base-form">
				<button
					:disabled="disableSubmitButton"
					class="btn btn-primary my-3"
					type="submit"
					name="action"
				>
					<b-spinner
						v-if="disableSubmitButton"
						style="margin-right: 10px; width: 1.3em; height: 1.3em;"
					/>
					{{ $t('send') }}
				</button>
				<div v-if="showPersonalData" class="custom-control custom-checkbox mb-3">
					<input
						id="personal-data-agreement"
						v-model="agreements.personal_data"
						type="checkbox"
						name="personalDataAgreement"
						class="custom-control-input"
						data-action="checkout-personal-data-agreement"
					>
					<label
						class="custom-control-label"
						for="personal-data-agreement"
					>
						{{ $t('agreeWith') }} <a target="_blank" href="https://www.skolkovo.ru/static/personaldata_rus.html" />{{ $t('personalData') }}
					</label>
					<label
						v-if="!$v.agreements.personal_data.checked"
						class="error"
						for="personal-data-agreement"
					>{{ $t('fieldRequired') }}</label>
				</div>
				<div v-if="showMarketingEmails" class="custom-control custom-checkbox mb-3">
					<input
						id="marketing-emails-agreement"
						v-model="agreements.marketing_emails"
						type="checkbox"
						name="marketingEmailsAgreement"
						class="custom-control-input"
						data-action="checkout-marketing-emails-agreement"
					>
					<label
						class="custom-control-label"
						for="marketing-emails-agreement"
					>{{ $t('isAgreesToMarketingEmails') }}</label>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapActions } from 'vuex';
import { required, maxLength, email } from 'vuelidate/lib/validators';

import InputPhone from '@/components/dev/InputPhone.vue';
import leadsMixin from '@/mixins/leadsMixin';
import userProfile from '~/mixins/userProfile';
import agreementsMixin from '@/mixins/agreementsMixin';
import DaDataInput from '@/components/DaDataInput.vue';
import DaDataCity from '~/components/daData/DadataCity.vue';
import DadataCompany from '~/components/daData/DadataCompany.vue';
import getTargetAction from '~/utils/getTargetAction';

export default {
	name: 'BaseForm',
	components: {
		InputPhone,
		DaDataInput,
		DaDataCity,
		DadataCompany,
	},
	mixins: [validationMixin, leadsMixin, userProfile, agreementsMixin],
	props: ['block', 'fetchedData', 'format', 'formTitle', 'scenario', 'scenarioBlockId', 'scenarioTitle'],
	validations() {
		const validators = {
			firstName: {
				required,
				maxLength: maxLength(150),
			},
			lastName: {
				required,
				maxLength: maxLength(150),
			},
			email: {
				required,
				email,
			},
			agreements: this.agreements_validation,
		};

		if (this.scenario !== 'REQUEST_SUBSCRIPTION') {
			validators.companyName = {
				required,
				maxLength: maxLength(200),
			};

			validators.industry = {
				required,
			};
		}

		if (this.format === 2) {
			if (this.scenario !== 'REQUEST_SUBSCRIPTION') {
				validators.jobTitle = {
					required,
					maxLength: maxLength(200),
				};
			}
			validators.city = {
				required,
				maxLength: maxLength(200),
			};
		}

		return validators;
	},
	data() {
		return {
			onlyCountries: ['RU'],
			firstName: '',
			lastName: '',
			email: '',
			mobilePhone: '',
			mobilePhoneInternational: '',
			amount: '',
			fullCompanyInfo: '',
			companyName: '',
			sklINN: '',
			industry: null,
			jobTitle: '',
			fullCityInfo: '',
			city: '',
			region: '',
			fias_id: '',
			geoname_id: '',
			student: true,
			comment: '',
			landingId: this.fetchedData.landingId,
			isMobilePhoneValid: false,
			showPhoneValidationError: false,
			terms: false,
			timestamp: null,
			generalLandingId: '99e8a00d-00b7-466a-8f72-47e1d8e0e85f',
			disableSubmitButton: false,
			industries: [],
		};
	},
	watch: {
		'$auth.user': {
			handler(user) {
				if (user) {
					this.firstName = user.first_name;
					this.lastName = user.last_name;
					this.email = user.email;
					this.mobilePhone = user.phone;
				}
			},
			immediate: true,
		},
		fullCompanyInfo: {
			handler(value) {
				if (value) {
					if (value.data) {
						this.sklINN = value.data.inn;
					}
					this.companyName = value.value;
				} else {
					this.companyName = null;
					this.sklINN = null;
				}
			},
		},
		fullCityInfo: {
			handler(value) {
				if (value && value.data) {
					this.city = value.data.settlement ?? value.data.city;
					this.region = value.data.region;
					this.fias_id = value.data.fias_id;
					this.geoname_id = value.data.geoname_id;
				} else {
					this.city = null;
					this.region = null;
					this.fias_id = null;
					this.geoname_id = null;
				}
			},
			immediate: true,
		},
	},
	async created() {
		const { data: industries } = await this.$api.expertBlocks();
		this.industries = industries;

		await this.setUserProfileFields();
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
		}),
		async setUserProfileFields() {
			const profile = await this.getUserProfile();

			if (profile) {
				this.companyName = profile.company_name;
				this.jobTitle = profile.job_title;
				this.industry = profile.company_industry_id ?? null;
				this.city = profile.location;
			}
		},
		phoneUpdate(payload) {
			this.showPhoneValidationError = !payload.isValid;
			this.isMobilePhoneValid = payload.isValid;
			this.mobilePhoneInternational = payload.formatInternational;
		},

		async submit() {
			if (this.fetchedData.status) {
				this.generalLandingId = this.fetchedData.landing_id;
			}

			this.$v.$touch();
			if (!this.isMobilePhoneValid) {
				this.showPhoneValidationError = true;
				return;
			}
			if (this.$v.$invalid) {
				return;
			}
			this.showPhoneValidationError = false;
			this.disableSubmitButton = true;

			this.setAgreementFields();

			const leadData = this.makeLeadData();
			try {
				await this.sendLead(leadData);
				try {
					await this.sendCoronaSignUp(leadData);
				} catch (error) {
					this.$logger.log(error);
				} finally {
					this.pushToDataLayer(leadData);
					this.redirectAfterSubmit();
				}
			} catch (error) {
				this.$logger.log(error);
			}
		},
		makeLeadData() {
			const leadData = {
				landingId: this.generalLandingId,
				isHeadForm: 1,
				leadFields: this.makeLeadFields(),
				company_inn: this.sklINN,
				company_industry_id: this.industry,
				city: this.city || '',
				fias_id: this.fias_id ?? this.geoname_id ?? '',
				industries: this.industry ?? '',
				branch: this.industry ?? '',
			};

			if (this.fetchedData.admission) {
				leadData.admissionId = this.fetchedData.admission.id;
			}

			return leadData;
		},
		// eslint-disable-next-line complexity
		makeLeadFields() {
			const industryField = this.industries.find((el) => el.id === this.industry);
			const leadFields = {
				TsContactGivenName: this.firstName || '',
				TsContactSurname: this.lastName || '',
				Email: this.email || '',
				MobilePhone: this.mobilePhoneInternational || '',
				Account: this.companyName || '',
				sklINN: this.sklINN || '',
				City: this.city || '',
				Region: this.region || '',
				sklIndustryOKVED: industryField ? industryField.name_ru : '',
				...this.makeFormatSpecificLeadFields(),
				sklAgreeSubscribeLookup: this.agreements.marketing_emails ? 'да' : 'нет',
				sklAgreedPersonalData: this.agreements.personal_data,
				sklTargetAction: getTargetAction(this.scenario, 1),
			};

			if (!this.fetchedData.status) {
				leadFields.Commentary = ` | ${
					this.fetchedData.type_titles.title_default
				} | ${
					this.fetchedData.title
				} | ${
					this.fetchedData.project_title
				} | ${
					this.fetchedData.event_date}`;

				if (this.fetchedData.issue) {
					leadFields.Commentary = `${leadFields.Commentary} | ${this.fetchedData.issue.title}`;
				}
			}

			return leadFields;
		},
		makeFormatSpecificLeadFields() {
			if (this.format === 2) {
				return {
					FullJobTitle: this.jobTitle || '',
					City: this.city || '',
					TsAdditionalInformation: `Запрос на: ${this.scenarioTitle}
					| ${this.city ? `Город: ${this.city}` : ''}
					| ${this.fetchedData.admission ? `Адмиссия: ${this.fetchedData.admission.title}` : ''}`,
				};
			}
			return {};
		},
		async sendCoronaSignUp(leadData) {
			return this.$axios({
				url: `${process.env.baseURL}/api/corona/sign-up`,
				data: {
					scenario: this.scenario,
					scenarioBlockId: this.scenarioBlockId,
					eventId: this.fetchedData.id,
					additionalUserData: {
						userId: this.$auth.loggedIn ? this.$auth.$state.user.id : null,
					},
					...leadData,
				},
				method: 'post',
				contentType: 'application/x-www-form-urlencoded',
				crossDomain: true,
			});
		},
		pushToDataLayer(leadData) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'form_send',
				form_data: leadData,
			});
		},
		redirectAfterSubmit() {
			if (this.format === 1) {
				top.location.href = `/${this.fetchedData.typology_slug}/${
					this.fetchedData.slug
				}/ok-success/?openid=${this.generalLandingId}`;
			} else {
				top.location.href = `/${this.fetchedData.typology_slug}/${
					this.fetchedData.slug
				}/request-success/?openid=${
					this.generalLandingId
				}&scenario=${this.scenario}&scenarioBlockId=${this.scenarioBlockId}`;
			}
		},
	},
};
</script>

<style lang="scss">
@import 'assets/styles/sk_variables';
// For use bootstrap vars and mixins inside
@import "assets/styles/bootstrap/scss/functions";
@import "assets/styles/bootstrap/scss/variables";
@import "assets/styles/bootstrap/scss/mixins";

	// @import "~/assets/styles/components/dev/discourse-registration";
	.dadata-form {
		& input {
			width: 100%;
			border: none !important;
			background: none;
			color: white !important;
			padding: .55rem 0rem .55rem 0rem !important;
			font-size: 1rem !important;
			font-weight: 400 !important;
			font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
		}
	}
	.header_registration {
		.input-tel__input,
		.country-selector__input {
			&::placeholder {
				color: rgba(169,169,169, .7) !important;
			}
		}
	}
	.select-form {
		&__disabled {
			color: rgba(169,169,169, .7) !important;
		}
		option {
			color: black;
		}
	}

	.another-checkbox {
		position: absolute;
		right: 5px;
		top: 10px;
	}
	.company-input {
		input {
			padding-right: 80px;
		}
		input:disabled.vs__input {
			background-color: inherit;
		}
	}
</style>

<i18n lang="yaml">
ru:
  registration-on: "Регистрация на"
  registration-closed: "Регистрация закрыта"
  notifications-note: "Если вы хотите получать уведомления о мероприятиях на смежную тему, оставьте, пожалуйста, свои данные."
  support-note: "Мировая практика показывает, что развитие бизнес-школ невозможно без регулярной поддержки ее выпускников и партнеров. Культура пожертвования — наша миссия для обеспечения устойчивости и взаимоподдержки в предпринимательском сообществе. Оставляя свои контактные данные, Вы даете нам возможность поблагодарить Вас персонально."
  select-all: "Выбрать все"
  fill-name-message: "Пожалуйста, укажите ваше имя"
  fill-last-name-message: "Пожалуйста, укажите вашу фамилию"
  fill-second-name-message: "Пожалуйста, укажите ваше отчество"
  fill-company-name-message: "Пожалуйста, укажите вашу отрасль"
  fill-industry-message: "Пожалуйста, укажите вашу компанию/проект"
  fill-job-message: "Пожалуйста, укажите вашу должность"
  job-placeholder: "Должность"
  fill-phone-message: "Пожалуйста, укажите ваш номер телефона"
  fill-email-message: "Пожалуйста, укажите ваш email"
  valid-email-message: "Пожалуйста, укажите валидный email"
  city-placeholder: "Город"
  fill-city-message: "Пожалуйста, укажите ваш город"
  fill-university-message: "Пожалуйста, укажите ваш университет"
  university-placeholder: "Университет"
  fill-birthday-message: "Пожалуйста, укажите ваш  день рождения"
  birthday-placeholder: "Дата вашего рождения дд.мм.гггг"
  are-you-student: "Вы студент / выпускник?"
  no: "Нет"
  yes: "Да"
  comment-placeholder: "Какой вопрос вы бы хотели задать спикерам?"
  fill-comment-message: "Пожалуйста, укажите комментарий"
  comment-max-length: "Длина сообщения не должна превышать 300 символов"
  country-selector-error: "Выберите страну"
  phone-number-label: "Введите ваш номер телефона"
  example: "Пример:"
  agreement: "согласие на обработку моих персональных данных"
  sending-form-i-give: "Отправляя форму, я даю"
  and: "и"
  accept-the-terms-of-the-public-offer-agreement: "принимаю условия договора публичной оферты"
  agreement-required: "Необходимо принять условия"
  support: "Поддержать"
  send: "Отправить"
  sending: "Отправляется"
  name-placeholder: "Имя"
  last-name-placeholder: "Фамилия"
  second-name-placeholder: "Отчество"
  company-placeholder: "Компания"
  industry-placeholder: "Отрасль"
  english-level: "Уровень английского"
en:
  registration-on: "Registration for the"
  registration-closed: "Registration closed"
  notifications-note: "If you want to receive notifications about events on related topics, please, leave your details."
  support-note: "Мировая практика показывает, что развитие бизнес-школ невозможно без регулярной поддержки ее выпускников и партнеров. Культура пожертвования — наша миссия для обеспечения устойчивости и взаимоподдержки в предпринимательском сообществе. Оставляя свои контактные данные, Вы даете нам возможность поблагодарить Вас персонально."
  select-all: "Select all"
  fill-name-message: "Please, fill your name"
  fill-last-name-message: "Please, fill your last name"
  fill-second-name-message: "Please, fill your second name"
  fill-company-name-message: "Please, fill your company/project"
  fill-industry-message: "Please, fill your industry"
  fill-job-message: "Please, fill your job"
  job-placeholder: "Position"
  fill-phone-message: "Please, fill your phone number"
  fill-email-message: "Please, fill your email"
  valid-email-message: "Please, fill valid email"
  city-placeholder: "City"
  fill-city-message: "Please, fill your city"
  fill-university-message: "Please, fill your university"
  university-placeholder: "University"
  fill-birthday-message: "Please, fill your birthday"
  birthday-placeholder: "Your birthday in format dd.mm.yyyy"
  are-you-student: "Are you student or graduate?"
  no: "No"
  yes: "Yes"
  comment-placeholder: "What question would you like to ask to speakers?"
  fill-comment-message: "Please, leave your comment"
  comment-max-length: "The comment may not be greater than 300"
  country-selector-error: "Choose your country"
  phone-number-label: "Fill your phone number"
  example: "Example:"
  agreement: "consent to the processing of my personal data"
  sending-form-i-give: "Sending form I give"
  and: "and"
  accept-the-terms-of-the-public-offer-agreement: "accept the terms of the public offer agreement"
  agreement-required: "You must accept the terms"
  support: "Support"
  send: "Send"
  sending: "Sending"
  name-placeholder: "Name"
  last-name-placeholder: "Last name"
  second-name-placeholder: "Second name"
  company-placeholder: "Company"
  industry-placeholder: "Industry"
  english-level: "English Level"
</i18n>
