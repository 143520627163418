<template>
	<div class="block-bio">
		<div class="row">
			<div class="col-12 col-lg-3">
				<div class="subtitle">
					{{ block.block_title }}
				</div>
			</div>
			<div class="col-12 col-lg-9">
				<div class="content" v-html="this.content.html.ru" />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'HtmlRawBio',
	props: ['content', 'block', 'fetchedData'],
};
</script>
<style lang="scss" scoped>
	@import "~/assets/styles/components/dev/html-raw-bio";
</style>
