<template>
	<vue-phone-number-input
		v-model="phoneNumber"
		name="phone"
		default-country-code="RU"
		:color="color"
		:border-radius="0"
		:no-country-selector="noCountrySelector"
		:no-flags="true"
		:no-example="true"
		:show-code-on-list="true"
		:error="error"
		:translations="{
			countrySelectorLabel: '',
			countrySelectorError: $t('country-selector-error'),
			phoneNumberLabel: $t('phone-number-label'),
			example: $t('example'),
		}"
		:disabled="disabled"
		autocomplete="tel"
		v-bind="$attrs"
		v-on="inputListeners"
		class="mb-1"
	/>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
	components: {
		VuePhoneNumberInput,
	},
	inheritAttrs: false,
	props: {
		color: { type: String, default: '#000000' },
		noCountrySelector: { type: Boolean, default: true },
		disabled: { type: Boolean, default: false },
		error: { type: Boolean, default: false },
	},
	data() {
		return {
			phoneNumber: '',
			phoneData: null,
		};
	},
	computed: {
		inputListeners() {
			if (this.$listeners.update) {
				return this.$listeners;
			}

			const vm = this;
			return {
				...this.$listeners,
				input() {
					// do nothing
				},
				update(event) {
					vm.phoneData = event;
					vm.$emit('input', event.formattedNumber || event.phoneNumber);
					vm.$emit('validatePhone', event.isValid);
				},
			};
		},
	},
	watch: {
		phoneData(value) {
			if (value.countryCode === 'RU' && this.phoneNumber && this.phoneNumber.startsWith('8')) {
				this.phoneNumber = value.formattedNumber;
			}
		},
	},
	created() {
		this.checkValue();
	},
	methods: {
		checkValue() {
			if (this.$attrs.value) {
				if (this.$attrs.value.startsWith('8') || this.$attrs.value.startsWith('7')) {
					this.phoneNumber = this.$attrs.value.slice(1);
				} else {
					this.phoneNumber = this.$attrs.value;
				}
			}
		},
	},
};
</script>
<style lang="scss">
.has-value .input-tel__label {
	display: none !important;
}
</style>

<style lang="scss" scoped>
@import "assets/styles/sk_variables";
// For use bootstrap vars and mixins inside
@import "assets/styles/bootstrap/scss/functions";
@import "assets/styles/bootstrap/scss/variables";
@import "assets/styles/bootstrap/scss/mixins";

.vue-phone-number-input .country-selector__input, .vue-phone-number-input .input-tel__input {
	font-weight: 400 !important;
	color: $input-color !important;
	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&::-ms-input-placeholder,
	&::placeholder {
		font-weight: 400 !important;
	}
}

.form-control, .vue-phone-number-input .country-selector__input, .vue-phone-number-input .input-tel__input {
	border: none !important;
	border-bottom: $input-border-width solid $input-border-color !important;
	color: $input-color !important;
	box-shadow: none !important;
	display: block;
	width: 100%;
	height: $input-height !important;
	padding: $input-padding-y $input-padding-x !important;
	font-family: $input-font-family !important;
	@include font-size($input-font-size !important);
	font-weight: $input-font-weight;
	line-height: $input-line-height !important;
	background-color: transparent !important;
	background-clip: padding-box;
	border-radius: 0;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input-tel__label {
	top: -8px !important;
}
</style>

<i18n lang="yaml">
ru:
  country-selector-error: "Выберите страну"
  phone-number-label: "Введите значение"
  example: "Пример:"
en:
  country-selector-error: "Choose your country"
  phone-number-label: "Enter value"
  example: "Example:"
</i18n>
