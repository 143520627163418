<template>
	<div class="event_menu">
		<div class="container">
			<div class="swiper_menu">
				<div v-swiper:menuSwiper="options">
					<div class="swiper-wrapper">
						<div v-for="(link, index) in links" :key="index" class="swiper-slide">
							<a
								v-if="link.home"
								:href="link.path"
								class="home icon-home"
							/>
							<a
								v-else-if="link.active"
								:href="link.path"
								:class="{active: linkActiveButtons[index]}"
							>
								{{ isStaticTranslate ? $t(link.name) : link.name }}
							</a>
						</div>
					</div>
					<div class="event-button-prev icon-pin_left" />
					<div class="event-button-next icon-pin_right" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { getLinkId } from '@/utils/urlHelper';

export default {
	name: 'LineMenu',
	components: {
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	props: ['links', 'currentPath'],
	data() {
		return {
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				cssMode: true,
				loop: false,

				navigation: {
					nextEl: '.event-button-next',
					prevEl: '.event-button-prev',
				},
			},
			linkActiveButtons: [],
		};
	},
	created() {
		const path = this.currentPath || this.$route.fullPath;
		const id = getLinkId(path, this.links);
		this.linkActiveButtons = [];
		this.linkActiveButtons[id] = true;
	},
	computed: {
		isStaticTranslate() {
			return ['management-detail', 'professor-detail'].includes(this.$route.name);
		}
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/event-menu";
</style>
