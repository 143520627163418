<template>
	<div id="discourse_broadcast" class="panel discourse-broadcast theme-default">
		<div class="container">
			<div id="counter-block" class="row ">
				<div class="col-12 col-lg-6">
					<div
						class="counter_block theme-image"
						:style="'background-image: url('+block.content.image.src+')'"
					>
						<div class="timer_wrapper">
							<div class="title">
								Событие<br>начнется через:
							</div>
							<client-only>
								<flip-countdown
									:deadline="fetchedData.event_date"
									:server_time="fetchedData.server_time"
								/>
							</client-only>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="registration theme-light">
						<div class="title">
							{{ block.block_title }}
						</div>
						<base-form
							:fetched-data="fetchedData"
							:format="1"
							:scenario="EVENT_FORM_SCENARIOS.REGISTRATION"
						/>
					</div>
				</div>
			</div>
			<div v-if="fetchedData.instruction" id="youtube-block" class="row d-none ">
				<div class="col-12">
					<div class="broadcast_block">
						<b-embed
							type="iframe"
							aspect="16by9"
							:src="fetchedData.instruction"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { EVENT_FORM_SCENARIOS } from '@/utils/dictionaries';
import FlipCountdown from '~/components/refactor/FlipCountdown.vue';
import BaseForm from '../dev/BaseForm.vue';

export default {
	name: 'RegistrationCounterImage',
	components: {
		BaseForm,
		FlipCountdown,
	},
	props: [
		'block',
		'fetchedData',
	],
	data() {
		return {
			EVENT_FORM_SCENARIOS,
		};
	},
};
</script>

<style lang="scss">
	@import "~/assets/styles/components/dev/discourse-broadcast";
</style>
