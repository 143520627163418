<template>
	<div class="panel">
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-6">
					<h4 class="title mb-4">
						Для просмотра данной страницы Вам необходимо быть членом сообщества выпускников Школы
					</h4>
				</div>
			</div>
			<div v-if="statusSending && sending && !statusRejected">
				<button class="btn btn-primary mb-4" @click="sendRequest">
					<b-spinner v-if="loader" style="margin-right: 15px; width: 1.3em; height: 1.3em;" />
					<span v-if="loader">Отправляется</span>
					<span v-else>Авторизоваться и пройти верификацию</span>
				</button>
			</div>

			<div v-if="statusRejected && !statusSending" class="alert alert-danger mb-4">
				В доступе отказано
			</div>
			<div v-if="statusSending && sending && statusRejected">
				<button class="btn btn-primary mb-4" @click="sendRequest">
					<b-spinner v-if="loader" style="margin-right: 15px; width: 1.3em; height: 1.3em;" />
					<span v-if="loader">Отправляется</span>
					<span v-else>Авторизоваться и пройти верификацию повторно</span>
				</button>
			</div>

			<div v-if="(statusSent || statusSuccess) && !statusRejected" class="alert alert-warning mb-4">
				Спасибо за вашу заявку. Как только мы верифицируем ваш статус, вам на почту придет уведомление об этом.
			</div>
			<div @click="alumniRouting" class="mb-4">
				<button class="btn btn-secondary">
					Вернуться на главную
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'AlumniHeader',
	props: {
		eventPage: {
			type: Object,
		},
		fetchedData: {
			type: Object,
		},
		event: {
			type: Object,
		},
		links: {
			type: Array,
		},
		btn: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			statusSuccess: false,
			sending: true,
			loader: false,
		};
	},
	computed: {
		statusSending() {
			return this.$auth?.user?.is_request_alumni === 0
				|| (!this.$auth.loggedIn || this.$auth?.user === null);
		},
		statusSent() {
			return this.$auth?.user?.is_request_alumni === 1;
		},
		statusRejected() {
			return this.$auth?.user?.is_alumni_rejected === 1;
		},
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
		}),
		checkIfLogged() {
			if (!this.$auth.loggedIn || this.$auth?.user === null) {
				this.showLoginPopup();
			}
		},
		async sendRequest() {
			if (!this.$auth.loggedIn || this.$auth?.user === null) {
				this.showLoginPopup();
			}
			if (this.$auth?.user?.is_request_alumni === 0) {
				this.loader = true;

				const response = await this.$api.alumniRequest(this.$route.fullPath);
				if (response.result === true) {
					this.statusSuccess = true;
					this.sending = false;
					this.loader = false;
				}
			}
		},
		alumniRouting() {
			if (this.$route.params.eventSlug && this.eventPage) {
				this.$router.push(`/${this.eventPage.event.typology_slug}/${this.eventPage.event.slug}/`);
			}
			if (this.fetchedData?.typology_slug) {
				this.$router.push(`/${this.fetchedData?.typology_slug}/${this.fetchedData?.slug}/`);
			}
			if (this.event?.slug) {
				this.$router.push('/');
			}
			if (this.links) {
				this.$router.push(this.links[0].path);
			}
		},
	},
};
</script>
