import { render, staticRenderFns } from "./EventHeader.vue?vue&type=template&id=bd32a75e&scoped=true"
import script from "./EventHeader.vue?vue&type=script&lang=js"
export * from "./EventHeader.vue?vue&type=script&lang=js"
import style0 from "./EventHeader.vue?vue&type=style&index=0&id=bd32a75e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd32a75e",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BPopover} from 'bootstrap-vue'
    installComponents(component, {BPopover})
    


    import installDirectives from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-tooltip': VBTooltip})
    

export default component.exports