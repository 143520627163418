<template>
  <div class="panel reviews" id="panel_reviews">
    <div class="container">
      <div class="panel_header">
        <div class="row align-items-end">
          <div class="col-12">
            <h2>Отзывы выпускников</h2>
          </div>
        </div>
        <!--<nuxt-link class="panel_control" to="#"></nuxt-link> item.listing_cover_image_url_default -->
      </div>
      <div class="review_block">
        <ul class="review_list row">
          <li v-for="(item, index) in this.block.content" v-bind:key="index" class="col-12 col-md-6 col-lg-3">
            <div :class="'review-item ' + contentIconMap[item.content_type]">
              <div>
                <nuxt-link :to="$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)">
                  <img :src="item.listing_cover_image_url_default" :alt="item.title">
                </nuxt-link>
                <div class="review-item_top">
                  <div class="tag_string">
                    <span><nuxt-link :to="$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)">Выпускник второго набора</nuxt-link></span>
                  </div>
                </div>
                <!--<div class="type">Эксперт</div> -->
                <nuxt-link :to="$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)" class="title">{{item.title}}</nuxt-link>
                <!--
                          <p class="description">
                            Ex-руководитель кластера Россия – СНГ, Ипсен Фарма (Ipsen)
                          </p>
                          -->
              </div>
              <div class="review-item_bottom">
                <nuxt-link :to="$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)" class="more_link">{{contentTypeMap[item.content_type]}}</nuxt-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!--
                              <div class="panel_footer">
                                  <div class="row">
                                      <div class="col-12 col-sm-8">
                                          <nuxt-link to="link" class="btn btn-primary">Все материалы</nuxt-link>
                                      </div>
                                      <div class="col-12 col-sm-4">
                                          <div class="panel_footer_info"></div>
                                      </div>
                                  </div>
                              </div>
                              -->
    </div>
  </div>
</template>

<script>
  export default {
    name: "prog-review",
    components: {},
    directives: {},
    props: [
      'block',
      'fetchedData',
    ],
    data() {
      return {
        contentTypeMap: {
          '0': 'Читать',
          '1': 'Смотреть',
          '2': 'Слушать'
        },
        contentIconMap: {
          '0': 'text',
          '1': 'video',
          '2': 'audio'
        },
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~/assets/styles/components/home/prog-review";
</style>
