<template>
	<header id="header-registration" class="header">
		<div
			class="header_wrapper"
			:class="filterActive"
			:style="headerStyle"
		>
			<top-menu-new :fetched-data="fetchedData" />

			<client-only v-if="videosSource && safariAgent">
				<picture id="video" class="embed-responsive embed-responsive-16by9">
					<source class="embed-responsive-item" :srcset="`${fetchedData.video_mp4}`" type="video/mp4">
					<img :src="`${fetchedData.top_cover_image_url_default}`">
				</picture>
			</client-only>

			<client-only v-if="videosSource && !safariAgent">
				<video
					id="video"
					playsinline
					muted
					autoplay
					preload="auto"
					loop
					class="embed-responsive embed-responsive-16by9"
					:poster="`${fetchedData.top_cover_image_url_default}`"
				>
					<source :src="`${fetchedData.video_mp4}`" class="embed-responsive-item" type="video/mp4">
				</video>
			</client-only>
			<div class="container mt-5 pt-lg-4 header_block">
				<div class="row pt-lg-5">
					<div
						:class="[
							fetchedData.is_hide_header_registration_form ?
								'col-12 col-lg-12 d-flex flex-column justify-content-center' :
								'col-12 col-lg-7 d-flex flex-column justify-content-center',
						]"
					>
						<div class="header_main">
							<div class="title_block">
								<div class="subtitle d-none d-lg-block">
									{{ fetchedData.type_titles.title_default }}
									<span
										v-if="fetchedData.taxonomy.section"
									> | {{ fetchedData.taxonomy.section.title }}</span>
									<span
										v-if="fetchedData.taxonomy.project"
									> | {{ fetchedData.taxonomy.project.title }}</span>
								</div>
								<h1>{{ fetchedData.title }}</h1>
								<div class="title">
									{{ fetchedData.subtitle }}
								</div>
							</div>
						</div>
						<div class="header_footer">
							<div class="row">
								<div class="col-12 col-md-12">
									<div class="announce_date_block justify-content-start flex-row border-0">
										<div class="announce_date_day">
											<span>{{ date | moment("DD") }}</span>
											<a
												v-if="showDate"
												v-b-tooltip.hover.top="$t('AddCal')"
												:href="'//sk.skolkovo.ru/api/calendar/event/' + fetchedData.id"
												class="add_announce"
												aria-label="$t('AddCal')"
											/>
										</div>
										<div class="announce_date_properties">
											<div class="announce_date_month">
												{{ date | moment('MMMM') }} {{ fetchedData.event_date | moment("YYYY") }}
											</div>
											<div class="announce_date_time">
												<span>{{ date | moment('HH:mm') }}</span>
											</div>
										</div>
										<div style="visibility: hidden; height: 1px; width: 1px;">
											{{ date | moment("DD MMMM YYYY") }}
										</div>
									</div>
								</div>

								<!-- Блок с селектором выбора даты мероприятия -->
								<div v-if="fetchedData.is_cyclic && sellableProgramsDropdown.length > 0" class="col-12 col-md-7">
									<div class="announce_date_selector">
										<p>Вы можете выбрать удобную дату старта</p>

										<b-dropdown
											block
											variant="secondary"
											menu-class="w-100"
										>
											<template #button-content class="rounded-0">
												<div v-if="dateSelected">
													{{ date | moment('DD MMMM YYYY, dddd') }}
												</div>
												<div v-else>
													Выберите подходящую дату
												</div>
											</template>
											<b-dropdown-item-button
												v-for="sellableProgram in sellableProgramsDropdown"
												:key="sellableProgram.id"
												type="button"
												name="action"
												@click="selectDate(sellableProgram.id), smartFormSelectProgram(sellableProgram.id)"
											>
												{{ sellableProgram.active_from | moment('DD MMMM YYYY, dddd') }}
											</b-dropdown-item-button>
										</b-dropdown>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!showDate" class="title_block mb-4">
							<div class="title">
								Регистрация закрыта
							</div>
						</div>
					</div>
					<div
						v-if="fetchedData.status && !fetchedData.is_hide_header_registration_form"
						class="col-12 col-lg-4 offset-lg-1"
					>
						<div id="header-registration-form" class="header_registration">
							<div v-if="$i18n.locale ==='en'" class="title">
								Request form
							</div>

							<div v-else class="title">
								{{ $t('request') }} {{ scenarioTitle }}
							</div>

							<base-form
								:fetched-data="fetchedData"
								:format="2"
								:form-title="formTitle"
								:scenario="scenario"
								:scenario-title="scenarioTitle"
								:scenario-block-id="scenarioBlockId"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!links" class="event_menu">
			<div class="container">
				<div class="swiper_menu">
					<div v-swiper:menuSwiper="options">
						<div class="swiper-wrapper">
							<div v-for="(block, index) in fetchedData.blocks" :key="index" class="swiper-slide">
								<a
									v-if="block.type ==='panel-navigation'"
									v-scroll-to="'#' + block.type + block.id"
									href="#"
								>{{ block.content.block_title.ru }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<line-menu v-else :links="links" />
	</header>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import moment from '@/utils/moment';
import { EVENT_FORM_SCENARIOS, HEADER_IMAGE_POSITIONS, BOOTSTRAP_BREAKPOINTS } from '@/utils/dictionaries';
import LineMenu from '@/components/layouts/_partials/LineMenu.vue';
import TopMenuNew from '@/components/layouts/_partials/TopMenuNew.vue';
import IssueMenu from '@/components/layouts/_partials/IssueMenu.vue';
import checkScreenWidth from '@/mixins/checkScreenWidth';
import BaseForm from '../../dev/BaseForm.vue';

export default {
	name: 'ProgramHeader',
	components: {
		BaseForm,
		TopMenuNew,
		IssueMenu,
		Swiper,
		SwiperSlide,
		LineMenu,
	},
	directives: {
		swiper: directive,
	},
	mixins: [checkScreenWidth],
	props: [
		'fetchedData',
		'links',
		'eventPage',
	],
	data() {
		return {
			videosSource: '',
			headerStyle: '',
			poster: '',
			color: '',
			filterActive: '',
			headerImagePosition: '',
			showDate: true,
			EVENT_FORM_SCENARIOS,
			BOOTSTRAP_BREAKPOINTS,
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				cssMode: true,
				loop: false,
			},
			date: this.fetchedData.event_date,
			dateSelected: false,
			formTitle: 'Запросить презентацию',
			scenarioBlockId: null, // ID формы откуда пришёл сценарий
			scenarioBlock: null, // Сценарий, который пришёл из формы
			safariAgent: null,
		};
	},
	computed: {
		sellableProgramsDropdown() {
			const dropdownData = [];

			if (
				typeof this.fetchedData.sellable_programs === 'undefined'
				|| this.fetchedData.sellable_programs === null
				|| this.fetchedData.sellable_programs.length === 0
			) {
				return dropdownData;
			}

			this.fetchedData.sellable_programs.forEach((element) => {
				if (moment(element.active_from).isSameOrAfter(moment(), 'day')) {
					dropdownData.push(element);
				}
			});

			return dropdownData;
		},
		scenario() {
			if (this.scenarioBlock !== null && typeof EVENT_FORM_SCENARIOS[this.scenarioBlock] !== 'undefined') {
				return EVENT_FORM_SCENARIOS[this.scenarioBlock];
			}

			if (typeof EVENT_FORM_SCENARIOS[this.fetchedData.scenario] !== 'undefined') {
				return EVENT_FORM_SCENARIOS[this.fetchedData.scenario];
			}

			return EVENT_FORM_SCENARIOS[EVENT_FORM_SCENARIOS.REQUEST_PRESENTATION];
		},
		scenarioTitle() {
			let scenarioTitle = null;

			if (this.scenarioBlockId !== null) {
				if (this.eventPage) {
					this.fetchedDataEventPage.blocks.forEach((block) => {
						if (block.id === this.scenarioBlockId) {
							scenarioTitle = block.custom_scenario_string
								|| block.scenarios_ru_accusative[this.scenario];
						}
					});
				} else {
					this.fetchedData.blocks.forEach((block) => {
						if (block.id === this.scenarioBlockId) {
							scenarioTitle = block.custom_scenario_string
								|| block.scenarios_ru_accusative[this.scenario];
						}
					});
				}
			} else if (this.fetchedData.blocks && this.fetchedData.blocks.length > 0) {
				this.fetchedData.blocks.forEach((block) => {
					if (block.scenarios_ru_accusative) {
						scenarioTitle = block.scenarios_ru_accusative[this.scenario];
					}
				});
			}

			if (scenarioTitle !== null) {
				return scenarioTitle;
			}

			return this.fetchedData.scenarios_ru_accusative[this.scenario];
		},
	},
	watch: {
		screenWidth() {
			this.loadStyles();
		},
	},
	created() {
		this.checkEndDate();
		this.headerImagePosition = HEADER_IMAGE_POSITIONS[this.fetchedData.mobile_image_center_position];
	},
	mounted() {
		if (process.client) {
			this.browserDetect();
			this.initVideo();
		}
		if (document.readyState === 'complete') {
			this.loadStyles();
		} else {
			window.addEventListener('load', this.loadStyles, {
				once: true,
				passive: true,
			});
		}
	},
	methods: {
		browserDetect() {
			if (navigator.vendor && navigator.userAgent) {
				this.safariAgent = navigator.vendor.includes('Apple')
					&& !navigator.userAgent.includes('CriOS')
					&& !navigator.userAgent.includes('FxiOS');
			}
		},
		initVideo() {
			if (this.fetchedData.video_mp4) {
				this.videosSource = `${this.fetchedData.video_mp4}`;
			}
		},
		loadStyles() {
			if (this.fetchedData.video_mp4) {
				this.filterActive = "filter";
				if (this.fetchedData.color) {
					this.headerStyle = `--new-color: ${this.fetchedData.color}`;
				}
			} else {
				if (!this.fetchedData.mobile_image.startsWith('https')) {
					this.headerStyle = `background-image:url(${this.fetchedData.top_cover_image_url_default})`;
				} else if (this.screenWidth > this.BOOTSTRAP_BREAKPOINTS.md) {
					this.headerStyle = `background-image:url(${this.fetchedData.top_cover_image_url_default})`;
				} else {
					this.headerStyle = `background-image:url(${this.fetchedData.mobile_image}); background-position: ${this.headerImagePosition}`;
				}
			}
		},
		selectDate(sellableProgramId) {
			this.fetchedData.sellable_programs.forEach((element) => {
				if (element.id === sellableProgramId) {
					this.dateSelected = true;
					this.date = element.active_from;
				}
			});
		},
		checkEndDate() {
			const today = moment().format('YYYY-MM-DD HH:mm');
			const datEnd = this.fetchedData.event_date_end;
			const end = moment(datEnd).format('YYYY-MM-DD HH:mm');
			const check = moment(today).isBefore(end);
			if (!check) {
				this.showDate = false;
			}
		},
		smartFormSelectProgram(sellableProgramId) {
			if (
				typeof this.$parent.$refs['panel-smart-form'] === 'undefined'
				&& 0 in this.$parent.$refs['panel-smart-form']
			) {
				return;
			}

			this.$parent.$refs['panel-smart-form'][0].selectSellableProgramAndScroll(sellableProgramId, false);
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/prog-header";
	@import "~/assets/styles/components/home/event-menu";
</style>

<style lang="scss">
	@import "assets/styles/sk_variables";
	// For use bootstrap vars and mixins inside
	@import "assets/styles/bootstrap/scss/functions";
	@import "assets/styles/bootstrap/scss/variables";
	@import "assets/styles/bootstrap/scss/mixins";
	.header {
		.header_block {
			z-index: 2;
		}
		.filter {
			position: relative;
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				aspect-ratio: 1 / 1;
				background-color: var(--new-color, $primary) !important;
				mix-blend-mode: multiply;
				z-index: 1;
			}
		}
		#video {
			z-index: 0;
			position: absolute !important;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			overflow: hidden;
			display: flex;
			object-fit: cover;
			align-items: center;
			justify-content: center;
			width: 100% !important;
			height: 100% !important;
			filter: grayscale(1);

			img {
				max-width: unset;
			}
		}
	}
	.header_registration {
		@import "assets/styles/components/home/base-form-inverse";
	}
</style>
