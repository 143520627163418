import { render, staticRenderFns } from "./BaseForm.vue?vue&type=template&id=257d7229"
import script from "./BaseForm.vue?vue&type=script&lang=js"
export * from "./BaseForm.vue?vue&type=script&lang=js"
import style0 from "./BaseForm.vue?vue&type=style&index=0&id=257d7229&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BaseForm.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BSpinner, BFormSelectOption, BFormSelect} from 'bootstrap-vue'
    installComponents(component, {BSpinner, BFormSelectOption, BFormSelect})
    

export default component.exports