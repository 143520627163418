/* global yaCounter994527 */

// @vue/component
export default {
	methods: {
		enrichLeadData(data = {}) {
			const clientID = typeof yaCounter994527 !== 'undefined'
				? yaCounter994527.getClientID()
				: '';
			const { UAClientID } = window;
			const BpmRef = this.$cookies.get('bpmRef') || '';
			const BpmHref = this.$cookies.get('bpmHref') || '';
			const roistatVisitId = this.$cookies.get('roistat_visit') || '';

			return {
				...data,
				leadFields: {
					SkGAClientId:
						typeof UAClientID !== 'undefined' ? UAClientID : '',
					SkYMClientId:
						typeof clientID !== 'undefined' ? clientID : '',
					roistatVisitId: typeof roistatVisitId !== 'undefined' ? roistatVisitId : '',
					BpmRef,
					BpmHref,
					...(data.leadFields || {}),
				},
			};
		},
		async sendLead(leadData) {
			return this.$api.axios.$post('api/v2/leadsintegration/save', this.enrichLeadData(leadData));
		},
	},
};
