const jsonldForEvents = (data) => {
	// Предварительное формирование данных из `data` для повторяющихся элементов
	const name = data.meta_title || data.title;
	const startDate = `${data.event_date}+3:00`;
	const endDate = `${data.event_date_end}+3:00`;
	const url = `${process.env.baseFrontendURL}${data.typology_slug}/${data.slug}/`;
	const image = data.smm_image_default.src || data.top_cover_image_url_default;
	const description = data.meta_description || data.header_description;

	// Основные данные разметки
	let jsonldData = {
		'@context': 'http://schema.org',
		'@type': 'Event',
		name,
		description,
		startDate,
		endDate,
		// Событие произойдет или произошло, как положено. Бэк пока не умеет отправлять другие статусы
		eventStatus: 'https://schema.org/EventScheduled',
		// Тип события - онлайн. Все события на основном домене - онлайн, оффлайн улетают на поддомены?
		eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
		location: {
			'@type': 'VirtualLocation',
			url,
		},
		image,
		organizer: {
			'@type': 'Organization',
			name: 'Бизнес-школа СКОЛКОВО',
			url: 'https://www.skolkovo.ru',
		},
	};

	// Добавление ведущих
	if (data.speakers?.length) {
		const performer = [];
		data.speakers?.forEach((pers) => {
			performer.push({
				'@type': 'Person',
				name: pers.title,
			});
		});

		jsonldData = { ...jsonldData, performer };
	}

	// Добавление микроразметки для видео
	// Есть 18 типов событий, какие из них - с трансляцией, какие без - непонятно.
	// На бэке к сущности Event примешивается EventTranslation с 2 полями для видео
	// Если одно из них заполнено, будем считать, что это и есть трансляция
	if (data.instruction || data.video_stream_url) {
		const subjectOf = {
			'@type': 'VideoObject',
			name,
			description,
			embedUrl: url,
			thumbnailUrl: image,
			uploadDate: startDate,
			publication: {
				'@type': 'BroadcastEvent',
				isLiveBroadcast: true,
				startDate,
				endDate,
			},
		};
		jsonldData = { ...jsonldData, subjectOf };
	}

	// Profit
	return jsonldData;
};

export default jsonldForEvents;
