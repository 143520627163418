<template>
	<header class="header">
		<div class="header_wrapper" :style="headerStyle">
			<top-menu-new :fetched-data="fetchedData" />
			<div class="container mt-lg-5 pt-5 pt-lg-0">
				<div class="header_main row">
					<div class="col col-lg-2">
						<div
							class="announce_date_block"
						>
							<div class="announce_date_day">
								<span>{{ fetchedData.event_date | moment("DD") }}</span>
								<a
									v-if="showDate"
									v-b-tooltip.hover.top="$t('AddCal')"
									:href="'//sk.skolkovo.ru/api/calendar/event/' + fetchedData.id"
									class="add_announce"
									aria-label="$t('AddCal')"
								/>
							</div>
							<div style="visibility: hidden; height: 1px; width: 1px;">
								{{ fetchedData.event_date | moment("DD MMMM YYYY") }}
							</div>
							<div class="announce_date_properties">
								<div class="announce_date_month">
									{{ fetchedData.event_date | moment("MMMM") }}
									{{ fetchedData.event_date | moment("YYYY") }}
								</div>
								<div class="announce_date_time">
									<span v-if="fetchedData.id === 5042">6.30 pm</span>
									<span v-else-if="fetchedData.id === 5772">11:00 MSK<br>(09:00 CET)</span>
									<span v-else-if="fetchedData.id === 5762">11:00 МСК<br>(09:00 CET)</span>

									<span v-else>{{ fetchedData.event_date | moment("HH:mm") }}</span>
								</div>
								<div class="announce_date_weekday">
									{{ fetchedData.event_date | moment('dddd') }}
								</div>
							</div>
						</div>
					</div>

					<div class="title_block col-lg-10">
						<div class="subtitle d-none d-lg-block">
							{{ fetchedData.type_titles.title_default }}

							<template v-if="fetchedData.taxonomy">
								<span
									v-if="fetchedData.taxonomy.section "
								> | {{ fetchedData.taxonomy.section.title }}</span>
								<span
									v-if="fetchedData.taxonomy.project && fetchedData.id !== 4872"
								> | {{ fetchedData.taxonomy.project.title }}</span>
							</template>
							<!--<span
								v-if="fetchedData.taxonomy.product"
							> | {{ fetchedData.taxonomy.product.title }}</span>-->
						</div>
						<h1 v-if="fetchedData.title_secondary !== null">
							{{ fetchedData.title_secondary }}
						</h1>
						<h1 v-else>
							{{ fetchedData.title }}
						</h1>
						<div class="title">
							{{ fetchedData.subtitle }}
						</div>
					</div>
				</div>
				<div v-if="showDate" class="header_footer row">
					<div class="col-12 col-lg-2" />
					<div class="col-12 col-lg-10">
						<p class="footer_description">
							{{ fetchedData.header_description }}
						</p>
						<div class="footer_bottom d-md-flex align-items-md-center">
							<button
								v-if="scenario === 2"
								@click="scrollToForm"
								href="#"
								class="btn btn-lg btn-primary mt-4 mt-md-0"
							>{{ $t('give') }}</button>
							<button
								v-else
								@click="scrollToForm"
								href="#"
								class="btn btn-lg btn-primary mt-4 mt-md-0"
							><span>{{ $t('register') }}</span></button>

							<button
								id="popover-project"
								class="btn btn-lg btn-primary mt-4 mt-md-0 ml-0 ml-md-3"
								tabindex="0"
							>
								{{ $t('share') }}
							</button>
							<b-popover target="popover-project" triggers="hover focus" placement="bottom">
								<template #title>
									{{ $t('shareSub') }}
								</template>
								<social-sharing inline-template>
									<div class="share_list">
										<!-- <network network="facebook">
											<i class="icon-fb" />
										</network> -->
										<network network="vk">
											<i class="icon-vk" />
										</network>
										<!-- <network network="twitter">
											<i class="icon-twitter" />
										</network> -->
										<network network="telegram">
											<i class="icon-telegram" />
										</network>
									</div>
								</social-sharing>
							</b-popover>

							<div v-if="fetchedData.participants_limitations" class="description">
								{{ $t('RegistrationRequired') }}
								<br>{{ $t('LimitedAccess') }}
							</div>
						</div>
					</div>
				</div>
				<div v-if="!showDate" class="header_footer row">
					<div class="col-12 col-lg-2" />
					<div class="col-12 col-lg-10">
						<div class="footer_description">
							Регистрация закрыта
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div v-if="fetchedData.id === 7032 || fetchedData.id === 7202" class="event_menu">
			<div class="container">
				<div class="swiper_menu">
					<div v-swiper:menuSwiper="options">
						<div class="swiper-wrapper">
							<div class="swiper-slide swiper-slide-active">
								<a href="/events/convent-2021/" class="home icon-home"></a>
							</div>
							<div class="swiper-slide">
								<a
									href="/events/convent-2021/alumni2021/"
								>Регистрация без пожертвования</a>
							</div>
							<div class="swiper-slide">
								<a
									href="/events/mile-2021/"
								>СКОЛКОВСКАЯ Миля</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="event_menu">
			<div class="container">
				<div class="swiper_menu">
					<div v-swiper:menuSwiper="options">
						<div class="swiper-wrapper">
							<div v-for="(block, index) in fetchedData.blocks" :key="index" class="swiper-slide">
								<a
									v-if="block.type ==='panel-navigation'"
									v-scroll-to="'#' + block.type + block.id"
									href="#"
								>{{ block.content.block_title.ru }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import { directive } from 'vue-awesome-swiper';
import TopMenuNew from '~/components/layouts/_partials/TopMenuNew.vue';
import { HEADER_IMAGE_POSITIONS, BOOTSTRAP_BREAKPOINTS } from '~/utils/dictionaries';
import _find from 'lodash/find';
import moment from '~/utils/moment';
import checkScreenWidth from '@/mixins/checkScreenWidth';

export default {
	name: 'EventHeader',
	components: {
		TopMenuNew,
	},
	directives: {
		swiper: directive,
	},
	mixins: [checkScreenWidth],
	props: { fetchedData: { type: Object, required: true } },
	data() {
		return {
			BOOTSTRAP_BREAKPOINTS,
			headerImagePosition: '',
			showDate: true,
			headerStyle: null,
			scenario: null,
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				cssMode: true,
				// freeMode: true,
				loop: false,
			},
		};
	},
	watch: {
		screenWidth() {
			this.loadStyles();
		},
	},
	created() {
		this.checkEndDate();
		this.headerImagePosition = HEADER_IMAGE_POSITIONS[this.fetchedData.mobile_image_center_position];
	},
	mounted() {
		if (document.readyState === 'complete') {
			this.loadStyles();
		} else {
			window.addEventListener('load', this.loadStyles, {
				once: true,
				passive: true,
			});
		}
		this.checkScenarios();
	},
	methods: {

		scrollToForm() {
      // получаем элемент формы
      const formElement = document.getElementById('empty-registration');

      // прокручиваем страницу до формы
    	this.$scrollTo(formElement, {
          duration: 0,
          easing: 'outCube'
      });
  	},

		loadStyles() {
			if (!this.fetchedData.mobile_image.startsWith('https')) {
				this.headerStyle = `background-image:url(${this.fetchedData.top_cover_image_url_default})`;
			} else if (this.screenWidth > this.BOOTSTRAP_BREAKPOINTS.sm) {
				this.headerStyle = `background-image:url(${this.fetchedData.top_cover_image_url_default})`;
			} else {
				this.headerStyle = `background-image:url(${this.fetchedData.mobile_image}); background-position: ${this.headerImagePosition}`;
			}
		},
		checkScenarios() {
			if (_find(this.fetchedData.blocks, { type: 'panel-smart-form' })) {
				const { scenario } = _find(this.fetchedData.blocks, { type: 'panel-smart-form' });
				return this.scenario = scenario;
			}
			return {};
		},
		checkEndDate() {
			const today = moment().format('YYYY-MM-DD HH:mm');
			const datEnd = this.fetchedData.event_date_end;
			const end = moment(datEnd).format('YYYY-MM-DD HH:mm');
			const check = moment(today).isBefore(end);
			if (!check) {
				this.showDate = false;
			}
		},

	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/event-header";
	@import "~/assets/styles/components/home/event-menu";
</style>
