// @vue/component
export default {
	data() {
		return {
			form: {
				fields: typeof this.getFields === 'function' ? this.getFields() : {},
				sending: false,
				sent: false,
				message: null,
				error: null,
				errors: {},
				response: null,
			},
		};
	},
	computed: {
		hasData() {
			return this.form.response?.data?.length > 0;
		},
		hasNextPage() {
			const meta = this.form.response?.meta;

			if (!meta) return false;

			if (meta.next_cursor !== undefined) return Boolean(meta.next_cursor);

			if (meta.last_page === undefined) {
				return true;
			}

			return meta.current_page < meta.last_page;
		},
	},
	watch: {
		'form.errors': {
			handler(errors) {
				if (Object.keys(errors).length) {
					this.form.message = null;
				}
			},
		},
	},
	methods: {
		valid(field) {
			if (this.form.errors[field] === undefined) return null;
			return !this.form.errors[field];
		},
		setError(key, errors) {
			if (errors) {
				this.$set(this.form.errors, key, errors);
			} else {
				this.$delete(this.form.errors, key);
			}
		},
		async onSubmit(data = {}) {
			if (this.form.sending) {
				return null;
			}

			try {
				this.form.sending = true;

				const response = await this.request({
					...this.form,
					fields: { ...this.form.fields, ...data },
				});

				this.form.error = null;
				this.form.errors = {};
				this.form.sent = true;

				return response;
			} catch (error) {
				this.form.sent = false;

				if (this.changeTriedToSend) {
					this.changeTriedToSend(true);
				}

				try {
					const { message, errors } = this.$api.getValidationFromError(error);
					this.form.error = message;
					this.form.errors = errors;
				} catch {
					this.$logger.exception(error, 'onSubmit', this);
					this.form.error = 'Что-то пошло не так';
					this.form.errors = {};
				}
			} finally {
				this.form.sending = false;
			}
			return null;
		},
		async nextPage() {
			const meta = this.form.response?.meta;
			if (!meta) {
				this.form.response = await this.onSubmit();
				return;
			}

			if (meta.next_cursor) {
				this.form.response = await this.onSubmit({
					cursor: meta.next_cursor,
				});
				return;
			}

			if (meta.current_page) {
				if (
					meta.last_page
					&& meta.current_page < meta.last_page
				) return;

				const response = await this.onSubmit({
					page: meta.current_page + 1,
				});

				if (response.data.length) {
					this.form.response = response;
					return;
				}

				this.form.response = {
					...(this.form.response || {}),
					meta: {
						last_page: response.data.length
							? response.meta.last_page : meta.current_page,
						...(this.form.response?.meta || {}),
						...(response?.meta || {}),
					},
				};
			}
		},
		async loadMore() {
			const meta = this.form.response?.meta;
			if (!meta) {
				this.form.response = await this.onSubmit();
				return;
			}

			if (meta.next_cursor === undefined) {
				throw new Error('Use cursor pagination for load more (inf scroll)');
			}

			const response = await this.onSubmit({
				cursor: meta.next_cursor,
			});

			this.form.response = {
				data: [
					...(this.form.response?.data || []),
					...(meta.next_cursor === response?.meta.next_cursor ? [] : (response?.data || [])),
				],
				links: {
					...(response?.links || {}),
				},
				meta: {
					...(response?.meta || {}),
					next_cursor: meta.next_cursor === response?.meta.next_cursor
						? null : response?.meta.next_cursor,
				},
			};
		},
	},
};
